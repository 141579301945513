@use '../common/mixins';
@import '../common/variables';

.toggleConsoleModeButton {
    height: 50px;
}

.consoleHeader {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    max-width: 1400px;
    background-color: white;
    padding: 20px;
    border-radius: $standardBorderRadius;
}

.consoleFilters {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;

    .consoleButtons {
        display: flex;
        gap: 30px;

        @media (max-width: 371px) {
            justify-content: center;
        }
    }

    @media (max-width: 1265px) {
        flex-wrap: wrap;

        .consoleButtons {
            flex-wrap: wrap;
        }
    }

    @media (max-width: 1070px) {
        margin-top: 90px;
    }
}

.usersSelect {
    width: 250px;
    padding: 15px;

    svg {
        width: 16px;
        height: 16px;
    }
}

.sessionSelect {
    width: 140px;
    padding: 15px;

    svg {
        width: 16px;
        height: 16px;
    }
}

.confirmButton {
    padding: 15px 30px;
    background-color: $base-primary-600;
    color: $base-0;

    &:hover {
        opacity: .9;
        color: $base-200
    }
}

.resetButton {
    padding: 15px 30px;
}

.consoleStats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid $base-100;
}

.consoleStatsItems {
    display: flex;
    align-items:center;
    gap: 30px;
    font-size: 14px;
    font-weight: 400;
    color: $base-500;

    svg {
        width: 16px;
        height: 16px;
        transform: translateY(2px);
    }
}

.consoleDownloadBtn {
    color: $base-primary-600;

    svg {
        width: 16px;
        height: 16px;
        transform: translateY(2px);
    }
}
