@import './common/variables';
@import 'common/mixins';

.drawer {
    position: fixed;
    top: 2%;
    right: 0;
    width: 400px;
    height: 96%;
    padding: 30px;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    z-index: 3000;
    border-radius: $standardBorderRadius;
    transform: translateX(100%);
  
    &.open {
        transform: translateX(0);
        will-change: transform;
    }

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}
