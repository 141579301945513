@import 'common/mixins';
@import 'common/variables';


.inputBox {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  // background-color: $base-50;

  .inputIcon {
    flex-shrink: 0;
    padding: 11px;
    background-color: $base-50;
    margin: auto 0;
    border-top-left-radius: $standardBorderRadius;
    border-bottom-left-radius: $standardBorderRadius;

    svg {
      transform: translateY(3px);
    }
  }

  .filterInput {
    width: 100px;
    flex-grow: 1;
    background-color: $base-50;
    border-top-right-radius: $standardBorderRadius;
    border-bottom-right-radius: $standardBorderRadius;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  & a,
  button {
    border-top-right-radius: $standardBorderRadius;
    border-bottom-right-radius: $standardBorderRadius;
    padding-right: 5px;
    color: rgba($dark, 0.4);
    background-color: $base-50;
    @include transition(color);

    &:hover {
      color: rgba($dark, 0.6);
    }
  }
}

.textFieldWrapper {
  display: flex;

  .textFieldInput {
    background-color: #00153505;
    padding: 15px;
    border-radius: $standardBorderRadius;
    width: 100%;

    &.token {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  button {
    border-radius: 0 $standardBorderRadius $standardBorderRadius 0;
    background-color: #00153505;
    padding-right: 10px;
  }
}

.textFieldError {
  border: 1px solid rgba(255, 0, 34, 0.607);
  border-radius: $standardBorderRadius;

  ::placeholder {
    color: rgba(255, 0, 34, 0.607);
  }
}

.filled {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}