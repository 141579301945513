@use '../common/mixins';
@import '../common/variables';

.consoleSection {
    flex-direction: column;
    height: calc(100vh - 90px);
    max-width: 1400px;
    margin: 0 auto;
  }

.sessionListWrapper {
    flex-grow: 1;
    justify-content: center;
    max-height: calc(100vh - 50px);
    overflow-y: scroll;
    max-width: 1400px;
    margin: 0 auto;

    @media (max-width: 1070px) {
      max-height: 100vh;
    }
}

.filledList {
    max-height: calc(100vh - 247px);
}

.sessionsList {
    display: flex;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 1400px;
    margin: 30px auto 0px auto;
    flex-direction: column;
    margin-top: 10px;
    border-radius: $standardBorderRadius;
    gap: 10px;
    font-weight: 400;
    padding-bottom: 10px;
}

.sessionsListItem {
    background: white;
    max-width: 1400px;
    // padding: 20px;
    border-radius: $standardBorderRadius;
    position: relative;
}

.sessionsListItemContainer, .sessionRecordsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;

    &.mainContainer {
        padding-bottom: 20px;
        border-bottom: 1px solid $base-100;
    }
}

.sessionRecordsContainer {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.cardInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.textPreview {
    display: flex;
    flex-direction: column;
}

.textPreviewUsername {
    font-size: 16px;
    font-weight: 500;
}

.textPreviewDate {
    font-size: 14px;
    color: $base-500;
}

.avatar {
    display: flex;
    box-sizing: content-box;
    width: 38px;
    height: 38px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
    color: white;
    background-color: $base-primary-600;

    &.selected {
        border-radius: 0;
        background-color: #fff
    }
}

.noResults {
    display: flex;
    max-width: 1400px;
    margin: 30px auto 0px auto;
    flex-direction: column;
    margin-top: 30;
    height: 65vh;
    background: white;
    border-radius: $standardBorderRadius;
    justify-content: center;
    align-items: center;
}

.title {
    color: #013B8E;
    font-size: 20;
    margin-top: 20;
}

.mainText {
    text-overflow: ellipsis;
}

.hideMainTextButton {
    position: absolute;
    top: 30px;
    right: 20px;

    svg {
        color: $base-500;
    }
}

.sessionsAudio {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 30px;
    gap: 5px;
    padding: 10px;
    border-radius: $standardBorderRadius;
    background-color: $base-primary-0;

    &.sessionsAudioTts {
        grid-template-columns: 1fr;
    }
}

.consolePlayPauseBtn,
.consoleVolumeBtn {
    svg {
        color: $base-primary-500;
    }
}

.consolePlayerTime {
    color: #9EB4D6;
}

.consoleSessionTooltip {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    display: flex;
    align-items: center;

    svg {
        color: $base-primary-500;
    }
}

.consoleSessionContent {
    color: #17244E;
    background-color: white;
    font-size: 16px;

    &:before {
        position: absolute;
        content: '';
        background: white;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 0.1rem;
      }
  
      &:global(.bottom-middle) {
        &:before {
          bottom: 100%;
          right: 50%;
          transform: rotate(45deg) translateX(75%);
        }
      }
}

.sessionRecords {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $base-500;
    padding: 10px;
    min-height: 48px;

    &:hover {
        .additionalInfo {
            display: inline;
        }
    }
}

.recordContent {
    display: flex;
    gap: 10px;

    span {
        display: block;
        padding-right: 30px;
        max-width: 1200px;
    }
}

.recordCustomPlayer {
    & :global(.volumeBtn),
    :global(.waveForm),
    :global(.currentTime),
    :global(.totalTime) {
      display: none;
    }
}

.recordInfo {
    display: flex;
    height: 48px;
    gap: 10px;
    align-items: center;
}

.additionalInfo {
    display: none;

    svg {
        color: $base-500;
    }

    &:hover {
        cursor: pointer;
        svg {
            color: $base-primary-500;
        }
    }
}

.recordConfidence {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: $standardBorderRadius / 3;
    font-size: 16px;
    padding: 2px 4px;
    height: 24px;

    &.blue {
        background-color: $base-primary-0;
        color: $base-primary-500;

        svg {
            color: $base-primary-500;
        }
    }

    &.red {
        background-color: #FFF1F2;
        color: #E11D48;

        svg {
            color: #E11D48;
        }
    }

    &.green {
        background-color: #ECFFC3;
        color: #65A30D;

        svg {
            color: #65A30D;
        }
    }
}
