@import './common/variables';
@import 'common/mixins';

.dateRangePickerWrapper {
  display: flex;
  width: 300px;
  max-height: 50px;
  padding: 15px;
  cursor: pointer;
  border-radius: $standardBorderRadius;
  position: relative;
  color: $base-500;
  user-select: none;
  z-index: 4;
  background-color: $base-0;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    text-overflow: hidden;
    line-height: 1rem;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.dateRangePickerCalendar {
  position: absolute;
  display: flex;
  width: 590px;
  height: 350px;
  top: 160px;
  left: 43%;
  right: 0;
  z-index: 5;
  font-weight: 400;
  padding: 8px;
  box-shadow: 0px 0px 14px -2px #0f1f481a;
  background-color: #ffffff;
  border-radius: $standardBorderRadius;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 64px;

    background: #0015351a;
    border-radius: 50px;
  }
}

.initialCalendar {
  border-right: 1px solid $base-100;
}

.calendar {
  width: 285px;
  border-radius: $standardBorderRadius;
  text-transform: capitalize;
  padding: 15px 15px 0px 15px;
  margin: auto;
}

.calendarHeader {
  position: relative;
  border-radius: $standardBorderRadius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.calendarHeaderArrowLeft {
  transform: scaleX(-1);
}

.calendarCurrentDate {
  padding: 5px 15px;
  border-radius: $standardBorderRadius;

  &:hover {
    cursor: pointer;
    background-color: $base-100;
  }
}

.calendarHeaderArrowRight,
.calendarHeaderArrowLeft {
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.calendarBody {
  border-radius: $standardBorderRadius;
  padding: 10px;
  font-size: 14px;
}

.calendarWeekNames {
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: $base-300;
  text-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(7, 32px);
  gap: 3px;
  margin-bottom: 10px;
}

.calendarDays {
  font-size: 14px;
  font-weight: 400;
  // color: var(--color-text);
  text-align: center;
  display: grid;
  text-align: center;
  align-items: center;
  grid-template-columns: repeat(7, 35px);
  grid-template-rows: 1fr;
  gap: 3px 0px;
}

.calendarDay {
  // box-sizing: border-box;
  border-radius: $standardBorderRadius / 2;
  height: 35px;
  width: 35px;
  cursor: pointer;
  justify-content: center;

  &:hover {
    background-color: $base-100;
  }
}

.calendarAdditionalDay {
  padding: 5.5px;
  font-weight: 300;
  cursor: pointer;
  color: $base-300;

  &:hover {
    background-color: inherit;
    cursor: default;
  }
}

.calendarDateInRange {
  border-radius: 0;
  color: $base-primary-600;
  background-color: $base-primary-0;
  &:hover {
    color: $base-primary-600;
    background-color: $base-primary-0;
  }
}

.calendarStartDay {
  border-top-left-radius: $standardBorderRadius / 2;
  border-bottom-left-radius: $standardBorderRadius / 2;
  background-color: $base-primary-0;
}

.calendarEndDay {
  border-top-right-radius: $standardBorderRadius / 2;
  border-bottom-right-radius: $standardBorderRadius / 2;
  background-color: $base-primary-0;
}

.calendarPickItem {
  // padding: 9px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: $standardBorderRadius;

  &:hover {
    cursor: pointer;
    background-color: $base-100;
  }
}

.calendarTodayItem {
  color: $pink;
  &:hover {
    color: $pink;
  }
}

.calendarSelectedItem {
  color: $base-0;
  background-color: $base-primary-600;

  &:hover {
    background-color: $base-primary-600;
  }
}

.calendarPickItemsContainer {
  font-weight: 400;
  // color: var(--color-text);
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 80px);
  grid-template-rows: repeat(4, 60px);
  gap: 1px 1px;
  font-size: 16px;
}

.calendarUnchoosableYear {
  font-weight: 400;
  padding: 9px 16px;
  color: $base-300;
}