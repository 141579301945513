// primary
$base-primary-600: #013b8e;
$base-primary-500: #014EBC;
$base-primary-400: #1B78FD;
$base-primary-300: #76aefe;
$base-primary-200: #a4c9fe;
$base-primary-100: #d1e4ff;
$base-primary-0: #f0f6ff;

// base
$base-900: #001535;
$base-700: #4c5b72;
$base-600: #667386;
$base-500: #808a9a;
$base-400: #99a1ae;
$base-300: #B7BCC8;
$base-200: #e5e8eb;
$base-100: #f2f3f5;
$base-50: #00153505;
$base-0: #fafafb;
$base-bg: #f8f8f8;

$deepBlue: #013b8e;
$dark: #16193b;
$skyBlue: #d0e5ff;
$deepOrange: #f6b450;
$pink: #FF6895;
$pale-pink: #fadadd;

$standardBorderRadius: 12px;

$standardBoxShadow: 0px 20px 70px -22px rgba(0, 21, 53, 0.08);

// responsive
$largestContainer: 1680px;
$largeContainer: 1200px;
