@use '../common/mixins';
@import '../common/variables';

.feedbackContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    margin: 20px auto 0 auto;
    height: 70vh;
    background-color: #fff;
    border-radius: $standardBorderRadius;
}

.feedbackContent {
    max-width: 312px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feedbackTitle {
    color: $base-primary-600;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
}

.feedbackSecondaryTitle {
    color: $base-300;
    font-weight: 400;
}

.feedbackSubtitle {
    color: $base-300;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
}

.feedbackIconWrapper {
    height: 150px;
    overflow: hidden;
}
