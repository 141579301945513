@use '../common/mixins';
@import '../common/variables';

.usersList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
    max-width: 1400px;

    @media (max-width: 1070px) {
        margin-top: 190px;
    }
}

.userSettings {
    position: relative;
    display: grid;
    grid-template-columns: 30px 30px 1fr 1fr 50px;
    gap: 15px;
    align-items: center;
    background-color: white;
    border-radius: $standardBorderRadius;
    padding: 20px;
}

.userInfo {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.userAccesses {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

.accessButton {
    max-height: 48px;
    background: transparent;
    border-radius: $standardBorderRadius;
    color: $base-400;

    &:hover {
        transform:scale(1.03);
        transition: 0.3s;

        svg,
        svg path {
            transition: 0.3s;
        }
    }

    &.disabled {
        background-color: $base-100;

        &:hover {
            background-color: $base-100;
            color: $base-500;

            svg,
            svg path {
                color: $base-500;
                stroke: $base-500;
            }
        }
    }

    &.active {
        background: $base-primary-100;
        color: $base-primary-600;

        &:hover {
            background: $base-primary-100;
            color: $base-primary-600;

            svg,
            svg path {
                color: $base-primary-600;
                stroke: $base-primary-600;
            }
        }
    }

    transition: 0.3s;
}

.deleteUserBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $pink;
    // color: #FFB5CB;
    background-color: $pale-pink;
    color: #d5697e;

    svg {
        width: 22px;
        height: 22px;
    }

    &:hover {
        box-shadow: 8px 4px 8px rgba(0, 21, 53, 0.04), 0px 2px 2px rgba(0, 21, 53, 0.05), 0px 2px 0px rgba(0, 21, 53, 0.04);
        transform:scale(1.03);
        transition: 0.3s;
        color: #d5697e;
        fill: none;
        stroke: none;
        svg path {
            color: #d5697e;
            stroke: none
        }
    }
    transition: 0.3s;
}

.redirectBtn {
    // background-color: $base-primary-600;
    color: $base-primary-600;

    &:hover {
        // background-color: $base-primary-600;
        color: $base-primary-600;

        svg,
        svg path {
            color: $base-primary-600;
            stroke: $base-primary-600;
        }
    }
}

.changeMarker {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $deepOrange;
}
