@import '../common/variables';
@import '../common/mixins';

.ttsModal {
  width: 70%;
  max-height: 100vh;

  @media (max-width: 1229px) {
    width: 90%;
  }
}

.TtsModalSettings {
  max-width: 50%;

  @media (max-width: 1229px) {
    max-width: 700px;
  }

  @media (max-width: 670px) {
    max-width: 100%;
  }
}

.ttsModalVoicesWrapper {
  margin-top: 20px;
  gap: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: 55vh;
  overflow-y: scroll;
  margin: 0 auto;

  @media (max-width: 970px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 464px) {
    grid-template-columns: 0.75fr 0.75fr;
  }
}

.ttsModalVoiceItem {
  max-width: 375px;
  border: 0.0675rem solid transparent;
  padding: 10px;
  cursor: pointer;
  position: relative;
  border-radius: $standardBorderRadius;

  @media (max-width: 768px) {
    max-width: 308px;
  }

  @media (max-width: 606px) {
    max-width: 270px;
  }

  @media (max-width: 490px) {
    max-width: 45vw;
  }

  @media (max-width: 464px) {
    max-width: 40vw;
  }

  &.ttsPickedVoice {
    border: 0.0675rem solid $base-primary-0;
    background: $base-primary-0;

    & .ttsModalVoiceName {
      color: $dark;
    }
  }

  &:hover {
    background: $base-primary-0;

    & .ttsModalVoiceName {
      color: $dark;
    }
  }
}

.ttsModalVoiceAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: $standardBorderRadius;
  overflow-x: hidden;
  min-height: 127px;
  max-width: 100%;

  & img {
    background: $base-50;
    object-fit: cover;
    width: 100%;
    border-radius: $standardBorderRadius;
  }

  @media (max-width: 490px) {
    overflow-x: hidden;
    max-width: 180px;

    & img {
      object-fit: none;
      width: 270px;
    }
  }
}

.ttsModalVoiceContent {
  margin-top: 1.375rem;
  display: flex;
  justify-content: start;
  gap: 5px;
  flex-direction: row;
  width: 100%;
}

.ttsModalInfoIcons {
  display: flex;
  justify-content: start;
  gap: 5px;
  flex-direction: row;

  @media (max-width: 490px) {
    position: absolute;
    top: 17px;
    right: 17px;
  }
}

.ttsModalVoiceName {
  margin-top: 1px;
  margin-left: 5px;
  color: $dark;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  @include transition(color);

  & span {
    @include truncate;
  }

  & svg {
    flex-shrink: 0;
  }
}

.ttsModalContent {
  margin-top: 1rem;
  display: block;
  align-items: flex-start;
  height: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.ttsModalVoiceLanguage {
  color: $base-900;
  font-weight: 400;
  width: 25px;
  height: 25px;
  padding: 5px 5px 0px 5px;
  background-color: $base-200;
  text-align: center;
  font-size: 0.7rem;
  line-height: 1.25;
  border-radius: 100%;
  @include transition(color);

  @media (max-width: 490px) {
    color: $base-100;
    background-color: #0015354d;
  }
}

.ttsFiltersWrapper {
  // width: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin-bottom: 20px;

  @media (max-width: 606px) {
    flex-direction: column;
    flex-flow: column-reverse;
    gap: 0.5rem;
  }
}

.ttsSexFilterBtns {
  width: 100%;
  display: flex;
  gap: 0.5rem;

  & button {
    width: 50%;
    text-align: center;
    justify-content: center;
  }
}

.ttsModalSettingsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.ttsModalSettingsItem {
  display: grid;
  grid-template-columns: 1fr 6fr;
  align-items: center;
  // justify-content: space-between;
  margin: auto 0;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.ttsModalSettingInput {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  // position: relative;
  width: 100%;
  transform: translateX(-40px);

  & input {
    width: 100%;
    flex-grow: 1;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: $standardBorderRadius;

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      height: 100%;
      border-radius: $standardBorderRadius * 1.75;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      position: relative;
      appearance: none;
      border-radius: $standardBorderRadius;
      height: 2.95rem;
      width: 1.5rem;
    }

    &::-moz-range-track {
      background: $base-100;
      height: 3rem;
      border-radius: $standardBorderRadius * 1.75;
    }

    &::-moz-range-thumb {
      background-color: $base-100;
      height: 2rem;
      width: 1.5rem;
      border-radius: $standardBorderRadius;
    }

    &::-moz-range-progress {
      background-color: $base-primary-600;
      height: 220%;
      border-radius: $standardBorderRadius;
    }
  }

  & span {
    font-size: 1.05rem;
    color: $base-primary-600 !important;
    font-weight: 500;
  }
}

.ttsSexBtn {
  border: 0.0675rem solid rgba($base-primary-600, 0.2);

  &.active {
    color: $base-primary-600;
    border: 0.0675rem solid $base-primary-600;
  }
}

.playerModal {

  & :global(.volumeBtn),
  :global(.waveForm),
  :global(.currentTime),
  :global(.totalTime) {
    display: none;
  }

  & :global(.playPauseBtn) {
    margin: auto;
    width: 1.6rem;
    color: #fff;
  }

  position: absolute;
  bottom: 0;
  right: 1px;
  transform: translate(-30%, 50%);
  width: 3.9rem;
  height: 3.9rem;
  background: $base-primary-600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.25rem solid #fff;
  border-radius: 50%;
  transform: translateY(-40px) translateX(-20px);

  @media (max-width: 515px) {
    width: 3rem;
    height: 3rem;
  }
}

.inputRange {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  position: relative;
  margin-top: 0.5rem;

  & .inputRangeValue {
    width: 2.9rem;
    height: 2.9rem;
    padding: 1.2rem;
    color: $base-primary-600;
    border: 5px solid $base-primary-600;
    border-radius: $standardBorderRadius;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    pointer-events: none;
  }
}

.ttsModalTooltipContent {
  max-width: 300px;
}

.ttsModalTooltip {
  position: absolute;
  z-index: 2;
  top: 7%;
  right: 7%;
  color: white;
  @include transition(color);

  svg {
    width: 30px;
    height: 30px;
  }

  &:hover {
    color: $base-700;
  }
}

.ttsFiltersSeparator {
  width: 2px;
  height: 20px;
  font-size: 20px;
  margin: auto 0;
  color: $base-200;
}

.ttsFilterLineTitle {
  font-size: 0.875rem;
  color: $base-500;

  @media (max-width: 767px) {
    margin-left: 15px;
  }
}

.ttsFilterLineWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.ttsFilterBtn {
  height: 50px;
  background: $base-100;
  color: $base-600;
  border-radius: 5rem;
  @include transition2(color, background);
  text-overflow: ellipsis;

  @media (max-width: 606px) {
    height: 40px;

    & svg {
      height: 18px;
      width: 18px;
    }
  }

  & svg {
    height: 26px;
    width: 26px;
  }

  &.active {
    background: $base-primary-0;
    color: $base-primary-600;
  }
}

.ttsFemaleVoice {
  & .ttsModalOtherContent {
    & svg {
      background: $pink;
    }
  }
}

.ttsModalOtherContent {
  & svg {
    background: #1b78fd;
    color: #fff;
    border-radius: 50%;
  }
}

.ttsSettingsFooter {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 20px;
}

.ttsModalButtonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    max-width: 200px;
    padding: 16px 20px;
  }
}

.ttsUpdateCacheCheckbox {
  margin-top: 36px;
  align-items: center;
}

.ttsModalCheckbox {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 18px;
  height: 18px;
  background-color: white;
  z-index: 3;
  border-radius: 4px;

  @media (max-width: 490px) {
    left: 14%;
  }
}

.emojiDropdownWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  max-width: 420px;
  max-height: 270px;
  padding: 12px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 64px;

    background: #0015351a;
    border-radius: 50px;
  }

  .emojiDropdownItem {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    padding: 14px 12px;
    cursor: pointer;
  }

  .emojiDropdownActiveItem {
    color: $base-primary-600;
    background-color: $base-primary-0;
    border-radius: $standardBorderRadius;
  }

  .emojiDropdownImg {
    width: 20px;
    height: 20px;
  }
}
