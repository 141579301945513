@use '../common/mixins';
@import '../common/variables';

.asrToggleTextBody {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  justify-content: space-between;

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  @media (max-width: 970px) {
    flex-direction: row;
    gap: 1rem;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.asrResultOverlay {
  position: absolute;
  color: transparent;
  top: 0;
  left: 0;
  user-select: none;
}

.asrResultCloud {
  position: relative;
}

.asrResultBodyItem {
  width: 100%;

  @media (max-width: 970px) {
    background: $base-bg;
    border-radius: $standardBorderRadius;
    padding: 1.3rem;
    max-height: 15.625rem;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.asrResultBodyTitle {
  font-size: 0.75rem;
  color: $base-500;
  display: inline-flex;
  margin-bottom: 0.875rem;
  align-items: center;
  cursor: pointer;
  @include mixins.transition(color);

  & svg {
    flex-shrink: 0;
    margin-left: 0.25rem;
  }
  &:hover {
    color: $base-900;
  }
}

.asrContentSpanWord {
  cursor: pointer;

  &:hover {
    background-color: rgba($base-primary-600, 0.2);
  }

  &:active {
    background-color: rgba($base-primary-600, 0.4);
  }

  &.asrWordSelected {
    background-color: rgba($base-primary-600, 0.4);
  }
}

.asrResultFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem;
  color: rgba($dark, 0.4);
  margin-top: 1.5rem;

  & .autoAnswer {
    color: #ff3c2f;
    margin-right: 1rem;
  }

  @media (max-width: 970px) {
    justify-content: flex-start;
  }
}

.asrMessageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 6px;

  // padding-left: 30px;
  & .asrMessageFilename {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
    @include mixins.truncate;

    & button {
      display: flex;
      color: $base-400;
      @include mixins.transition(color);
      &:hover {
        color: $base-primary-600;
      }
    }

    & span {
      @include mixins.truncate;
    }
  }

  .asrMessageContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .asrMessageSettingsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .asrMessageSettings {
    display: flex;
    gap: 1rem;
    margin-left: 1rem;

    & :first-child {
      &:hover {
        svg path {
          color: $base-primary-600;
          stroke: none;
      }
      }
    }

    & button,
    & a {
      display: flex;
      color: $base-400;
      @include mixins.transition(color);
      &:hover {
        color: $base-primary-600;
      }
    }
  }
}

.messageHeaderInfo {
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.mainSettingsContainer {
  @media (max-width: 970px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.mainSetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.88rem;
}

.divider {
  flex-grow: 1;
  border-top: 1px dotted $base-200;
  height: 100%;
  align-self: flex-end;
  margin-bottom: 0.4rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.mainSettingTitle {
  font-size: 0.875rem;
  color: $base-400;
}

.additionalSettingWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  @media (max-width: 970px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
  }

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
  }
}

.additionalSetting {
  display: flex;
  gap: 0.5rem;
  font-size: 0.875rem;
  margin-left: -0.25rem;
  line-height: 1.1rem;

  & span {
    display: flex;
    color: $base-primary-600;
    margin-top: -0.3rem;
  }
}

.streamingTextWrapper {
  margin-top: 0.5rem;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: $standardBorderRadius;
  margin-bottom: 0.5rem;

  & .streamBtn {
    display: flex;
    justify-content: center;
  }
}

.streamTexts {
  margin-top: 1rem;
}

.streamText {
  text-align: center;
}

.streamingInfoText {
  font-size: 0.875rem;
  text-align: center;
  color: rgba($dark, 0.4);
  margin-top: 2rem;
}

.streamingBtn {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  color: $base-primary-600;

  @media (max-width: 768px) {
    width: 4rem;
    height: 4rem;
  }

  & .microphone {
    width: 70%;
    height: 70%;
    color: $base-primary-600;
    @include mixins.transition(color);

    @media (max-width: 768px) {
      width: 87%;
      height: 87%;
    }

    &.inactive {
      @include mixins.inactive;
    }

    &:hover {
      opacity: 0.9;
    }

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  & .streamingBtnSquare {
    width: 35%;
    height: 35%;
    border-radius: 0.2rem;
    background-color: rgba($base-primary-600, 0.8);
    animation: streamingBtn 1.7s ease-in-out infinite alternate;
    @include mixins.transition(all);

    @media (max-width: 768px) {
      width: 50%;
      height: 50%;
    }

    &:hover {
      background-color: $base-primary-600;
    }
  }
}

.streamingBtnWrapper {
  display: inline-flex;
  position: relative;

  &.streamingBtnWrapperLoading {
    & .streamingBtnShade,
    & .streamingBtnSquare {
      animation-fill-mode: forwards;
      animation-iteration-count: 0;
    }

    & .streamingBtnSquare {
      border-radius: 100%;
      background-color: transparent;
      border: 2px solid $base-primary-600;
      border-top-color: transparent;
      animation: rotate 1s linear infinite;
      @include mixins.transition(all);
    }
  }
}

.streamingBtnShade {
  position: absolute;
  width: 120%;
  height: 120%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 0.0675rem solid $base-primary-600;
  z-index: 0;
  opacity: 0;

  animation: streamingBtnShade 1.2s 0.2s ease infinite;

  &.shade2 {
    animation: streamingBtnShade2 1.2s ease infinite;
  }
}

.asrPlayerSmallWrapper {
  margin-top: 1.5rem;
}

.asrResult {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background: #fff;
  box-shadow: $standardBoxShadow;
  border-radius: $standardBorderRadius;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.serchedText {
  background-color: rgba($base-primary-600, 0.4);
}

@keyframes streamingBtnShade {
  0% {
    opacity: 1;
    width: 80%;
    height: 80%;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 120%;
    height: 120%;
  }
}
@keyframes streamingBtnShade2 {
  0% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 130%;
    height: 130%;
  }
}

@keyframes streamingBtn {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.92);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hideShowMessageButton {
  position: absolute;
  top: 25px;
  left: 20px;
  float: right;
  width: 32px;
  height: 32px;
}

.closeMessageButton {
  position: absolute;
  top: 25px;
  right: 20px;
  float: right;
  width: 32px;
  height: 32px;
  
  svg {
    color: $base-400;
  }
}
