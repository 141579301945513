@import '../common/variables';
@import '../common/mixins';

.ttsSidebarHeaderWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-top: 0.2rem;

  &.sidebarSmall {
    & .ttsVoiceContentCollapsed {
      width: 0;
      opacity: 0;
      padding: 0;
      transform: scale(0);
      pointer-events: none;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    width: 1rem;
    height: 100%;
    pointer-events: none;
    content: '';
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 1rem;
    height: 100%;
    pointer-events: none;
    content: '';
    background: linear-gradient(-270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
  }
}

.voiceTooltipContent {
  max-width: 404px;
  box-shadow: 0px 4px 8px rgba(0, 21, 53, 0.04), 0px 0px 2px rgba(0, 21, 53, 0.05), 0px 0px 1px rgba(0, 21, 53, 0.04);
  background: #fff;

  &:before {
    background: #fff !important;
  }
}

.ttsTooltipContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $base-900;
  font-size: 0.875rem;
  gap: 5px;
  padding: 5px;

  & .tooltipOtherInfo {
    color: #ffffff;
    display: flex;
    justify-content: center;

    & .tooltipSex {
      background: $base-primary-600;
      border-radius: 50%;
      display: flex;
    }

    & .tooltipFemale {
      background-color: $pink;
    }
  }
}

.ttsSidebarVoicesWrapper {
  position: relative;
  display: flex;
  flex-grow: 1;
  gap: 12px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 0.2rem;
  cursor: grab;
  @include scrollbars(0px, transparent, transparent);

  @media (max-width: 576px) {
    gap: 0.5rem;
  }
}

.ttsVoiceItem {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4rem;
  user-select: none;
  background-color: $base-bg;
  gap: 0.75rem;
  cursor: grab;
  @include transition(all);

  &.ttsAddVoiceBtn {
    flex-shrink: 0;
    margin: auto 12px;
    border-radius: 100%;
    padding: clamp(16px, 3vw, 25px) clamp(16px, 3vw, 25px);
    background: $base-primary-0;
    // min-width: 0;
    width: 4.375rem;
    height: 4.375rem;

    img {
      margin: auto;
    }

    @media (max-width: 576px) {
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  &.ttsSidebarOverflowBtn {
    width: 4.375rem;
    height: 4.375rem;
    padding: auto;
    justify-content: center;
    font-size: 1rem;
    flex-shrink: 0;
    @media (max-width: 576px) {
      width: 3.7rem;
      height: 3.7rem;
    }
  }

  .ttsCloseVoiceBtn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 4px 4px 1px 4px;
    border-radius: 100%;
    background-color: $base-primary-600;
  }
}

.ttsItemCollapsed {
  gap: 0;
  min-width: 0px;
}

.ttsVoiceAvatarWrapper {
  box-sizing: content-box;
  display: flex;
  padding: 4px;

  @include transition(background);

  .ttsVoiceAvatar {
    display: flex;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: $base-50;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 5px;
    }

    @media (max-width: 576px) {
      width: 54px;
      height: 54px;
    }
  }
}

.ttsVoiceContent {
  padding-right: 1.5rem;
  // font-size: 0.875rem;
  line-height: 1;
  @include transition2(opacity, width);

  & .languages {
    font-size: 0.75rem;
    @include transition(color);
    color: $base-400;
  }
}

.voiceName {
  max-width: 125px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  font-size: 1rem;
  color: $dark;
  @include transition(color);
  padding-bottom: 4px;
}

.voicesBtn {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: $base-primary-600;
  flex-shrink: 0;
  font-size: 0.875rem;

  @media (max-width: 576px) {
    // background: $base-bg;
    padding: 0.5rem;
    border-radius: 50%;
  }
}

.ttsVoiceEmotionMark {
  transform: translateY(-3px);
}

.ttsVoiceEmotion {
  position: absolute;
  left: 42px;
  bottom: 0;
  padding: 2px 2px 0px 2px;
  border-radius: 100px;
  background: #f8f8f8;
}

.ttsOverflowVoiceContent {
  max-height: 230px;
  max-width: 1000px;
  overflow-y: auto;
  overflow-x: visible;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 64px;

    background: #0015351a;
    border-radius: 50px;
  }
}

.ttsOverflowTooltipContent {
  background-color: white;
  max-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  
  &:before {
    background: white !important;
  }
}

.ttsEmotionTooltip {
  color: black;
  max-width: 390px;
  max-height: 700px;
}

.ttsOverflowVoicesWrapper {
  display: flex;
  flex-direction: row;
  background-color: white;
  max-width: 280px;
}

.ttsOverflowVoicesTooltip {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 5px;
}

.ttsOverflowVoiceItem {
  position: relative;
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  width: 216px;
  font-size: 1rem;
  color: $base-900;
  border-radius: $standardBorderRadius;

  &:hover {
    background: $base-primary-0;
  }
}

.ttsAvatarWraper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ttsOverflowVoicesOtherInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.ttsOverflowVoiceEmotion {
  box-sizing: content-box;
  padding: 4px 4px 2px;
  border-radius: 50%;
  background-color: $base-100;
}

.ttsOverflowVoicesCrossBtn {
  position: absolute;
  right: 7px;
  svg {
    width: 25px;
    height: 25px;
    color: $base-primary-600;
  }
}
