@import './common/mixins';
@import './common/variables';

.checkBoxItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.checkBoxItem input[type='checkbox'] {
  display: none;

  &~.checkBoxAsr {
    background: $base-200;

    &::before {
      background: #ffffff;
    }
  }

  &:checked {
    &~.checkBoxAsr {
      background: $base-primary-600;

      &::before {
        left: calc(100% - 1rem);
        background: #fff;
      }
    }

    &~.checkBoxText {
      color: $base-900;
    }
  }
}

.checkBoxItem .checkBoxAsr {
  border-radius: 22px;
  width: 2.25rem;
  height: 1.3rem;
  position: relative;
  display: block;
  @include transition(background);

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(70, 156, 183, 0.2);
    position: absolute;
    top: 50%;
    left: 0.3rem;
    box-shadow: $standardBoxShadow;
    transform: translateY(-50%);
    transition: 0.2s;
  }
}

.checkBoxItem .checkBoxText {
  color: $base-500;
  user-select: none;
  transition: 0.2s;

  &.active {
    color: $base-900;
  }
}

.checkBoxInput {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: $base-0;
  width: 20px;
  height: 20px;
  background-color: #0015350d;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;

  &:checked {
    background: $base-primary-600;
    padding-left: 3px;

    &::before {
      content: '\2713';
    }
  }
}

.checkBoxInputInverted {
  color: $base-primary-600;

  &:checked {
    background: $base-0;
  }
}

