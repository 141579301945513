.adminPageSection {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    max-width: 1400px;
    margin: 0 auto;
  }

.usersListWrapper {
    flex-grow: 1;
    justify-content: center;
    max-height: calc(100vh - 190px);
    overflow-y: scroll;

    @media (max-width: 1070px) {
      max-height: 100vh;
    }
}
