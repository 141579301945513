@use 'common/mixins';
@import 'common/variables';

.customButton {
  padding: 0.75rem;
  color: $base-500;
  background-color: #fff;
  border-radius: $standardBorderRadius;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  box-shadow: $standardBoxShadow;
  @include mixins.transition(color);
  flex-shrink: 1;

  &:hover {
    color: $base-primary-600;
  }

  & svg {
    flex-shrink: 0;

    &:hover {
      color: $base-primary-600;
    }

    &:hover path {
      stroke: $base-primary-600;
    }
  }
}

.activeButton {
  color: $base-primary-600;
  background-color: $base-primary-0;
}

.customButtonWrapper {
  position: relative;
  display: inline-flex;
}

.selectButtonContentWrapper {
  position: absolute;
  top: calc(100% + 0.5rem);
  background-color: #fff;
  padding: 1rem;
  right: 0;
  border-radius: $standardBorderRadius;
  transform: translateY(-10%);
  opacity: 0;
  pointer-events: none;
  z-index: 10000;
  scale: 0;
  transition: 0.35s transform ease, 0.35s opacity ease, scale 0s 0.35s ease;
  box-shadow: 0px 0px 14px 2px #0f1f481a;

  &.active {
    opacity: 1;
    scale: 1;
    transform: translateY(2%);
    transition: 0.35s transform ease, 0.35s opacity ease, scale 0s 0s ease;
    pointer-events: all;
  }

  &.triangledContent {
    &:before {
      position: absolute;
      content: '';
      background: #fff;
      width: 0;
      height: 0;
      border-left: 10px solid #fff;
      border-right: 10px solid #fff;
      border-bottom: 20px solid #fff;
      z-index: -100;
      // border-radius: 0.1rem;
      // box-shadow: 1px 1px 5000px rgba(0, 0, 0, 0.2);
    }

    &:global(.bottom-left) {
      &:before {
        bottom: 100%;
        right: 50%;
        transform: rotate(45deg) translateX(75%);
      }
    }

    &:global(.top-middle) {
      &:before {
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &:global(.top-left) {
      &:before {
        top: 100%;
        right: 15%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &:global(.right-middle) {
      &:before {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &:global(.left-middle) {
      &:before {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }

  @media (max-width: 400px) {
    width: 100px;
  }
}

.customButtonOutline {
  background-color: $base-50;
  @include mixins.transition2(color, border);

  &:hover {
    opacity: 0.8;
  }
}

.outClickCustomSelect {
  display: inline-flex;
}

.primaryBtn {
  width: 100%;
  background: rgba($base-primary-600, 1);
  color: #fff;
  justify-content: center;
  gap: 12px;
  border-radius: 5rem;
  // @include transition(background);

  &.disabled {
    background: rgba($dark, 0.2);
    cursor: not-allowed;
  }

  &:hover {
    background: $base-primary-600;
    color: #fff;

    &.disabled {
      background: rgba($dark, 0.2);
    }
  }
}
