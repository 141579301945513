@import 'common/mixins';
@import 'common/variables';

.skeleton {
    display: flex;
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, $base-primary-100, $base-primary-0, $base-primary-100);
    background-size: 200% 100%;
    animation: skeletonLoading 2s infinite;
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
    min-height: 137px;
}

.skeletonAvatar {
    border-radius: 50%;
    min-height: 190px;
    min-width: 120px;
    transform: translateY(-40px) translateX(-10px);
}

.skeletonCard {
    border-radius: standardBorderRadius;
}

@keyframes skeletonLoading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
