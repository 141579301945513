@use '../common/mixins';
@import '../common/variables';

.drawerTittle {
    display: inline-block;
    max-width: 300px;
    font-weight: 600;
    font-size: 20px;
    color: $base-primary-600;
}

.drawerCloseButton {
    position: absolute;
    top: 0px;
    right: 0px;

    svg {
        width: 20px;
        height: 20px;
        color: $base-500;
    }
}

.consoleDrawerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // float: left;
}

.consoleDrawerContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.consoleDrawerContentIntem {
    display: flex;
    justify-content: space-between;
}

.textKey {
    color: $base-700;
}

.textValue {
    color: $base-900;
}

.copyTextIcon {
    &:hover {
        cursor: pointer;
    }

    svg {
        transform: translateY(4px);
        color: $base-300;
    }
}

.consoleDrawerTextarea {
    width: 352px;
    height: 127px;
    padding: 10px;
    border-radius: $standardBorderRadius;
    background-color: $base-0;
}
