@use 'common/mixins';
@import 'common/variables';

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
}

.paginationButton {
    width: 36px;
    height: 36px;
    border: 0;
    border-radius: $standardBorderRadius / 2;
    color: $base-500;
    
    &:first-child {
        transform: scaleX(-1);
    }

    &:hover {
        background-color: $base-50;
    }


}

.paginationElipsis {
    padding: 5px 10px;
    color: #CCCCCC;
}

.active {
    color: $base-900;
    background-color: $base-100;

    &:hover {
        background-color: $base-100;
    }
}