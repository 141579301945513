@import './common/variables';
@import 'common/mixins';

.customSelectWrapper {
  padding: 0.75rem;
  cursor: pointer;
  border-radius: $standardBorderRadius;
  position: relative;
  color: $base-primary-600;
  user-select: none;
  z-index: 4;
  background-color: $base-0;
}

.customSelectListItems {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 5;
  font-weight: 400;
  padding: 8px;
  box-shadow: 0px 0px 14px -2px #0f1f481a;
  background-color: #ffffff;
  border-radius: $standardBorderRadius;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 64px;

    background: #0015351a;
    border-radius: 50px;
  }
}

.customSelectActive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;

  & span {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: inline-block;
    color: $base-900;
    @include truncate;
  }

  & svg {
    flex-shrink: 0;
    color: $base-500;
  }
}

.customMultiSelectItem {
  color: $base-900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem;
  border-radius: $standardBorderRadius / 1.5;
  @include transition(background);
  @include truncate;
  height: 47px;

  & span {
    @include truncate;
  }

  &:hover {
    background-color: $base-50;
  }
}

.outClickWrapper {
  position: relative;
}
