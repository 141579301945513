@use '../common/mixins';
@import '../common/variables';


.newUserInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;

    div {
        width: 100%;
    }
}

.modalUserAccesses {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.confirmationModalInfo {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
        color: $base-700;
    }
}

.confirmationModalDeleteBtn {
    background-color: $pale-pink;
    color: #d5697e;
    &:hover {
        background-color: $pale-pink;
        color: #d5697e;
    }
}
