@use '../common/mixins';
@import '../common/variables';


.adminHeaderWrapper {
    display: grid;
    grid-template-columns: 1fr 40px 30px 140px 145px;
    gap: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 100%;
    max-width: 1400px;
    background-color: white;
    padding: 20px;
    border-radius: $standardBorderRadius;

    button {
        &.disabled {
            background: rgba($dark, 0.2);
            cursor: not-allowed;
        }
    }
}

.adminFilterButtonContent {
    max-width: 230px;
}

.adminAddUserButton {
    transform: translateY(4px);

    &:hover {
        background-color: $base-primary-0;
    }
}