@import '../common/mixins';
@import '../common/variables';

.fileInputWrapper {
  color: $base-700;
  background-color: $base-0;
  border-radius: $standardBorderRadius;
  padding: 26px;
  margin-bottom: 35px;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & input {
    display: none;
  }

  & label {
    color: $base-primary-600;
    cursor: pointer;
    position: relative;
  }

  // @media (max-width: 768px) {
  //   font-size: 0.875rem;
  // }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 10px;
  }
}

.inputTitle {
  display: inline;
  justify-content: center;

  & span {
    font-size: 12px;
  }

  div {
    display: inline-block;
  }

  svg {
    margin-left: 3px;
    margin-bottom: -8px;
    width: 23px;
    height: 23px;
    color: $base-primary-600;
  }
}

.infoIcon {
  color: $base-500;
  display: flex;
  @include transition(color);

  &:hover {
    color: $base-900;
  }

  @media (max-width: 768px) {
    width: 1.3rem;
    height: 1.3rem;
    display: flex;

    & svg {
      width: 100%;
      height: 100%;
    }
  }
}

.asrSettingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 15px;

  & .aloneButton {
    width: 13.1rem;
    border-radius: $standardBorderRadius !important;
  }

  // @media (max-width: 768px) {
  //   flex-direction: column;
  //   justify-content: flex-start;
  //   align-items: flex-start;

  //   &:first-child {
  //     width: 100%;
  //   }
  //   &:nth-child(2) {
  //     width: calc(50% - 0.25rem);
  //     @media (max-width: 576px) {
  //       width: 100%;
  //     }
  //   }
  //   &:nth-child(3) {
  //     width: calc(50% - 0.25rem);
  //     @media (max-width: 576px) {
  //       width: 100%;
  //     }
  //   }
  //   &:nth-child(4) {
  //     width: 100%;
  //   }
  // }
}

.asrSettingSelectOutClick {
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 470px) {
    width: 100%;
  }
}

.asrSettingSelect {
  width: 13.255rem;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.asrSettingsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  // @media (max-width: 768px) {
  //   gap: 1.3rem 0.5rem;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  // }
}

.asrSettingItemTitle {
  display: flex;
  align-items: center;
  color: $base-700;

  @media (max-width: 470px) {
    flex-direction: row-reverse;
    font-size: 0.875rem;
  }
}

.asrOptionButtonsWrapper {
  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 470px) {
    width: 100%;
  }
}

.asrOptionButton {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.asrModalBtn {
  display: flex;
  align-items: center;
  color: $base-500;
  @include transition(color);
  gap: 0.2rem;

  &.active {
    color: $base-primary-600;
  }

  &:hover {
    color: $base-primary-600;
  }

  & span {
    font-size: 1rem;
  }
}
.asrModalBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    justify-content: center;
    width: 100%;
  }
}

.loaderWrapper {
  height: 3.0625rem;
  width: 13.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.asrAdditionalBtnsWrapper {
  position: absolute;
  background-color: #ffffff;
  padding: 1.5rem;
  z-index: 6;
  border-radius: $standardBorderRadius;
  border: 0.0675rem solid $base-primary-0;
  width: 28.2rem;
  max-width: 95vw;
  top: 98%;
  left: 50%;
  transform: translate(-50%, -48%) scale(65%);
  opacity: 0;
  pointer-events: none;
  box-shadow: $standardBoxShadow;
  @include transition2(opacity, transform);

  &.asrAdditionalActive {
    opacity: 1;
    transform: translate(-50%, -68%) scale(100%);
    pointer-events: all;
  }
}

.asrAdditionalBtnsContainer {
  margin-top: 20px;

  &.closed {
    display: none;
  }
}

.asrAdditionalBtns {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.asrAdditionalBtnsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $base-primary-600;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.asrAdditionalClose {
  display: flex;
  color: rgba($base-primary-600, 0.5);
  @include transition(color);

  &:hover {
    color: $base-primary-600;
  }
}

.formStreaming {
  color: rgba($dark, 0.6);
  pointer-events: none;
}

.formRecordBtnWrapper {
}

.inactive {
  @include inactive;
}

.formRecordBtn {
  width: 6rem !important;
  height: 6rem !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 20px 70px -22px rgba(28, 71, 135, 0.15);
  border: none !important;
  @include transition(box-shadow);

  &:hover {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 20px 30px -22px rgba(28, 71, 135, 0.2);
  }
}

.asrSidebarFormWrapper {
  width: 100%;
  transition: 0.7s;
  flex-shrink: 0;
  max-width: 28.25rem;
  margin: 0 auto;

  &.active {
    width: 27.2rem;
    margin-top: 0;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    max-width: unset;
  }
}

.asrSidebarForm {
  margin: 0 auto;
  position: relative;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 1.5rem 4.5rem -1.5rem rgba(28, 71, 135, 0.08);
  padding: 1.5rem;
}

.reactMic {
  display: none;
}

.asrSection {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.asrRightSide {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.7s;

  &.asrRightActive {
    opacity: 1;
    flex-grow: 1;
    height: auto;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.asrContentRight {
  margin-top: 0.5rem;
  height: calc(100vh - 56px - 80px - 80px - 62px);

  @media (max-width: 1280px) {
    height: auto;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }
}
